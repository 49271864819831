import { DeriveFnEnv, DeriveFnResult } from "./types";
import {
  TinyColor,
  mostReadable as tcMostReadable,
  isReadable,
} from "@ctrl/tinycolor";
import { isEmpty, map } from "lodash";
import { generate as generateColors } from "@k-vyn/coloralgorithm";

function getColor({ colors, scope }: DeriveFnEnv, name: string) {
  let color;
  if (scope) color = colors[`${scope}-${name}`]?.color;
  if (!color) color = colors[name]?.color;

  if (!color)
    throw `Color with name "${name}" not found! (scope=${scope} theme=${JSON.stringify(
      colors,
    )})`;
  return color;
}

export function val(this: DeriveFnEnv, c: string): DeriveFnResult {
  const color = getColor(this, c);

  return { color, debug: `val(${c})` };
}

export function mix(
  this: DeriveFnEnv,
  c1: string,
  c2: string,
  amount: number,
): DeriveFnResult {
  const color1 = getColor(this, c1);
  const color2 = getColor(this, c2);

  return {
    color: new TinyColor(color1)
      .mix(new TinyColor(color2), amount)
      .toHexString(),
    debug: `mix(${c1}, ${c2}, ${amount})`,
  };
}

export function mostReadable(
  this: DeriveFnEnv,
  c: string,
  ...candidateNames: string[]
): DeriveFnResult {
  const color = getColor(this, c);
  const candidates = map(candidateNames, (c) => getColor(this, c));
  if (isEmpty(candidates)) candidates.push("white", "black");

  return {
    color:
      tcMostReadable(color, candidates, {
        includeFallbackColors: true,
      })?.toHexString() || color,
    debug: `mostReadable(${c}, ${candidateNames.join(", ")})`,
  };
}

export function shade(
  this: DeriveFnEnv,
  c: string,
  amount: number,
): DeriveFnResult {
  const color = getColor(this, c);

  return {
    color: new TinyColor(color).shade(amount).toHexString(),
    debug: `shade(${c}, ${amount})`,
  };
}

export function shadeOrTint(
  this: DeriveFnEnv,
  c: string,
  amount: number,
): DeriveFnResult {
  const color = getColor(this, c);
  const tColor = new TinyColor(color);
  let result;
  if (tColor.isLight()) result = tColor.shade(amount);
  else result = tColor.tint(amount);

  return {
    color: result.toHexString(),
    debug: `shadeOrTint(${c}, ${amount})`,
  };
}

export function lighten(
  this: DeriveFnEnv,
  c: string,
  amount: number,
): DeriveFnResult {
  const color = getColor(this, c);

  return {
    color: new TinyColor(color).lighten(amount).toHexString(),
    debug: `lighten(${c}, ${amount})`,
  };
}

export function makeReadable(this: DeriveFnEnv, c: string): DeriveFnResult {
  let color = getColor(this, c);

  while (!isReadable(color, "white", { level: "AAA", size: "small" })) {
    color = new TinyColor(color).shade(5).toHexString();
  }

  return {
    color,
    debug: `makeReadable(${c})`,
  };
}

export function variant(
  this: DeriveFnEnv,
  c: string,
  variant: number,
  options?: {
    saturation?: { start?: number; end?: number; rate?: number };
  },
): DeriveFnResult {
  if (!(variant >= 0 && variant <= 9))
    throw "variant must be in the range of 0-9";

  const { h: hue } = new TinyColor(getColor(this, c)).toHsv();

  const [{ colors }] = generateColors(
    // Play around with this at https://colorbox.io/
    {
      steps: 10,
      hue: { start: hue, end: hue, curve: "easeInQuad" },
      saturation: {
        start: 0.08,
        end: 1,
        curve: "easeInSine",
        rate: 1,
        ...options?.saturation,
      },
      brightness: { start: 1, end: 0.2, curve: "easeInSine" },
    },
  );

  return {
    color: map(colors, "hex")[variant],
    debug: `variant(${c}, ${variant})`,
  };
}

export type DeriveExp =
  | ["val", ...Parameters<typeof val>]
  | ["mix", ...Parameters<typeof mix>]
  | ["mostReadable", ...Parameters<typeof mostReadable>]
  | ["shade", ...Parameters<typeof shade>]
  | ["shadeOrTint", ...Parameters<typeof shadeOrTint>]
  | ["lighten", ...Parameters<typeof lighten>]
  | ["makeReadable", ...Parameters<typeof makeReadable>]
  | ["variant", ...Parameters<typeof variant>];
