import {
  DerivedTheme,
  ModifiedTheme,
  Theme,
} from "components/administration/designManager/types";
import { useSelector } from "react-redux";
import { State } from "../../../@types";
import { defaultTheme } from "components/administration/designManager/defaultThemes";
import { omit, pickBy } from "lodash";
import { useMemo } from "react";
import deriveColors from "components/administration/designManager/deriveColors";
import { colorRules } from "components/administration/designManager/rules";
import { ThemeType } from "components/administration/designManager/api";

export function loadOverrideTheme(): Theme | null {
  const themeRaw = localStorage.getItem("overrideTheme");
  let theme = null;
  if (themeRaw) theme = JSON.parse(themeRaw);
  return theme;
}

// If the possibility of a test drive is desired, context_slug and theme_type must be included here
export function storeOverrideTheme(theme: Theme | null) {
  if (theme) localStorage.setItem("overrideTheme", JSON.stringify(theme));
  else localStorage.removeItem("overrideTheme");
}

type UseThemeOptions = {
  baseTheme?: ModifiedTheme | Theme;
  derive?: "always" | "onOverride";
  themeType?: ThemeType;
};

export function useTheme({
  baseTheme,
  derive,
  themeType,
}: UseThemeOptions = {}): DerivedTheme {
  if (!derive) derive = "onOverride";

  // Select theme from state
  const themeFromState = useSelector((state: State) => {
    switch (themeType) {
      case "network":
        return state.theme;
      case "groupCategory":
        return state.groupCategoryTheme;
      case "group":
        return state.groupTheme;
      case "channel":
        return state.channelTheme;
      default:
        return state.theme;
    }
  });

  // Determine if deriveColors should happen
  const shouldDerive =
    derive === "onOverride" ? themeFromState?.override : derive === "always";

  const [derivedColors, themeWithDefaults] = useMemo(() => {
    if (!baseTheme) baseTheme = defaultTheme;

    // Add everything from default theme that might be missing in themeFromState
    const themeWithDefaults = {
      ...omit(baseTheme, ["isPreset"]),
      ...themeFromState,
      colors: {
        ...("base" in baseTheme ? baseTheme.base.colors : {}),
        ...baseTheme.colors,
        ...pickBy(themeFromState?.colors, "color"),
      },
    };

    const derivedColors = shouldDerive
      ? deriveColors(themeWithDefaults.colors, colorRules)
      : null;

    return [derivedColors, themeWithDefaults];
  }, [shouldDerive, baseTheme, themeFromState]);

  return { ...themeWithDefaults, derivedColors };
}
